import Vue from "vue";
import Vuex, { Store } from "vuex";
import * as AuthService from "../services/AuthApi";
import * as AfiliadoService from "../services/AfiliadoApi";
import * as AgendaServiceST from "../services/AgendaApi";
import * as AgendaServiceDx from "../services/AgendaDx";
import * as EstadisticaService from "../services/Estadisticas";
import moment from "moment";
import * as ApiSaludTotal from "../store/modules/ApiSaludTotal"
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userTypes: [
      { value: null, text: "Seleccione" },
      { value: "AFI", text: "Afiliado" },
      { value: "ASE", text: "Gente Comercial" },
      { value: "EJE", text: "Gente Comercial" },
      { value: "EMP", text: "Empleadores" },
      { value: "IND", text: "INDEPENDIENTE" },
      { value: "IPS", text: "IPS" },
      { value: "PAC", text: "AfiliadosPac" },
      { value: "PAF", text: "AfiliadosPacYPos" },
      { value: "PAR", text: "Asesor Especialista" },
    ],
    identificationTypes: [
      { value: null, text: "Seleccione" },
      // { value: "C", text: "Cédula de Ciudadanía", id: 1, valueAdx: 'CC' },
      // { value: "T", text: "Tarjeta de Identidad", id: 2, valueAdx: 'TI' },
      // { value: "R", text: "Registro Civil", id: 3, valueAdx: 'RC' },
      // { value: "N", text: "Nit", id: 4 },
      // { value: "E", text: "Cédula de Extranjería", id: 5 },
      // { value: "CD", text: "Carnet Diplomático", id: 11 },
      // { value: "CN", text: "Certificado Nacido Vivo", id: 13 },
      // { value: "J", text: "Personería Jurídica", id: 6 },
      // { value: "M", text: "Menor de Edad", id: 7 },
      // { value: "MS", text: "Menor Sin Identificación", id: 8 },
      // { value: "NU", text: "Número de Identificación Personal", id: 9 },
      // { value: "P", text: "Pasaporte", id: 10 },
      // { value: "PE", text: "Permiso Especial de Permanencia", id: 14 },
      // { value: "SC", text: "Salvoconducto", id: 12 },
      { value: "C", text: "Cédula de Ciudadanía", id: 1, valueAdx: 'CC', idInterfaz: 1 },
    { value: "T", text: "Tarjeta de Identidad", id: 2, valueAdx: 'TI', idInterfaz: 2 },
    { value: "R", text: "Registro Civil", id: 3, valueAdx: 'RC', idInterfaz: 4 },
    { value: "N", text: "Nit", id: 4, valueAdx: 'NI'},
    { value: "E", text: "Cédula de Extranjería", id: 5, valueAdx: 'CE', idInterfaz: 3 },
    { value: "CD", text: "Carnet Diplomático", id: 11, valueAdx: '' },
    { value: "CN", text: "Certificado Nacido Vivo", id: 13, valueAdx: '' },
    { value: "J", text: "Personería Jurídica", id: 6, valueAdx: '' },
    { value: "M", text: "Menor de Edad", id: 7, valueAdx: '' },
    { value: "MS", text: "Menor Sin Identificación", id: 8, valueAdx: 'MS', idInterfaz: 7 },
    { value: "NU", text: "Número de Identificación Personal", id: 9, valueAdx: 'NU' },
    { value: "P", text: "Pasaporte", id: 10, valueAdx: 'PA', idInterfaz: 6 },
    { value: "PE", text: "Permiso Especial de Permanencia", id: 14, valueAdx: 'PE', idInterfaz: 8 },    
    { value: "SC", text: "Salvoconducto", id: 12, valueAdx: 'SV' },
    { value: "AS", text: "Adulto Sin Identificación", id: 15, valueAdx: 'AS'},
    { value: "PT", text: "Permiso Protección Temporal", id: 16, valueAdx: 'PT'},
    ],
    layout: "MainLayout",
    tokenAfiliado: null,
    tokenAgenda: null,
    tokenAuth: null,
    grupoFamiliar: [],
    especialidades: [],
    cita: {
      afiliado: null,
      especialidad: null,
      idCita: null,
      fecha: null,
      origen: null,
      cups: ''
    },
    currentStep: 0,
    afiliado: {},
    // urlProxyApi: "http://186.116.13.121/proxy-citasweb",
    urlProxyApi: "https://api-citasweb-aevyjd6gbs3s.alianzadx.com/api",
    //urlProxyApi: "http://localhost:9000/api",
    userAdmin: localStorage.getItem("userAdmin"),
    fechaIngresoAdmin: localStorage.getItem("fechaIngresoAdmin"),
    esAfiliadoSaludTotal: false,
    nombreAfiliadoAdx: null,
    cancelFromLinkMessage: false
  },
  mutations: {
    setLayout: (state, payload) => {
      state.layout = payload;
    },
    logout: (state) => {
      state.tokenAfiliado = null;
      state.tokenAgenda = null;
      state.tokenAuth = null;
      state.grupoFamiliar = [];
      state.especialidades = [];
      state.cita = {
        afiliado: null,
        especialidad: null,
        fecha: null,
        origen: null,
        cups: ''
      };
      localStorage.removeItem("tokenAfiliado");
      localStorage.removeItem("tokenAgenda");
      localStorage.removeItem("tokenAuth");
      localStorage.removeItem("grupoFamiliar");
      localStorage.removeItem("especialidades");
      localStorage.removeItem("afiliado");
      state.currentStep = 0;
    },
    nextStep: (state) => {
      state.currentStep += 1;
    },
    prevStep: (state) => {
      state.currentStep -= 1;
    },
    resetStep: (state) => {
      state.currentStep = 0;
      state.cita = {
        afiliado: null,
        especialidad: null,
        fecha: null,
        origen: null,
        cups: null
      };
    },
    // Set tokens
    setTokenAfiliado: (state, payload) => {
      state.tokenAfiliado = payload;
      localStorage.setItem("tokenAfiliado", JSON.stringify(payload));
    },
    setTokenAgenda: (state, payload) => {
      state.tokenAgenda = payload;
      localStorage.setItem("tokenAgenda", JSON.stringify(payload));
    },
    setTokenAuth: (state, payload) => {
      state.tokenAuth = payload;
      localStorage.setItem("tokenAuth", JSON.stringify(payload));
    },
    setGrupoFamiliarData: (state, payload) => {
      state.grupoFamiliar = payload;
      localStorage.setItem("grupoFamiliar", JSON.stringify(payload));
    },
    setEspecialidadesData: (state, payload) => {
      state.especialidades = payload;
      localStorage.setItem("especialidades", JSON.stringify(payload));
    },
    setAfiliadoData: (state, payload) => {
      state.afiliado = payload;
      localStorage.setItem("afiliado", JSON.stringify(payload));
    },
    // Asignar datos de la cita
    setAfiliadoCita: (state, payload) => {      
      state.cita.afiliado = payload;
    },
    setEspecialidadCita: (state, payload) => {
      state.cita.especialidad = payload.codigo;
      state.cita.nomEspecialidad = payload.nombre;
      state.cita.origen = payload.origen;
    },
    setFechaCita: (state, payload) => {
      state.cita.fecha = payload;
    },
    setIdCita: (state, payload) => {
      state.cita.idCita = payload;
    },
    setCupCita: (state, payload) => {
      state.cita.cups = payload;
    },
    setCupsAfiliado: (state, payload) => {
      state.afiliado.cups = payload;
    },
    setEdadAfil: (state, payload) => {
      state.cita.afiliado.edad = payload;
    },
    setAuthStep: (state, payload) => {
      state.afiliado.authStep = payload;
    },
    setAfiliadoSaludTotal: (state, payload) => {
      state.esAfiliadoSaludTotal = payload;
    },
    setNombreAfiliadoAdx: (state, payload) => {
      state.nombreAfiliadoAdx = payload;
    },
    setLoggedFromLinkMessage: (state,payload) => {
      state.cancelFromLinkMessage = payload
    }
  },
  actions: {
    nextStep: (context) => {
      context.commit("nextStep");
    },
    prevStep: (context) => {
      context.commit("prevStep");
    },
    resetStep: (context) => {
      context.commit("resetStep");
    },
    setLayout: (context, payload) => {
      context.commit("setLayout", payload);
    },
    isLogged: (context) => {
      let localTokenAuth = JSON.parse(localStorage.getItem("tokenAuth"));
      let localTokenAfiliado = JSON.parse(
        localStorage.getItem("tokenAfiliado")
      );
      let localTokenAgenda = JSON.parse(localStorage.getItem("tokenAgenda"));
      let localGrupoFamiliar = JSON.parse(
        localStorage.getItem("grupoFamiliar")
      );
      let localEspecialidades = JSON.parse(
        localStorage.getItem("especialidades")
      );
      let localAfiliado = JSON.parse(localStorage.getItem("afiliado"));
      if (localTokenAfiliado !== null) {
        if (localTokenAfiliado !== null) {
          context.commit("setTokenAfiliado", localTokenAfiliado);
        }
        if (localTokenAgenda !== null) {
          context.commit("setTokenAgenda", localTokenAgenda);
        }
        if (localTokenAuth !== null) {
          context.commit("setTokenAuth", localTokenAuth);
        }
        if (localGrupoFamiliar !== null) {
          context.commit("setGrupoFamiliarData", localGrupoFamiliar);
        }
        if (localGrupoFamiliar !== null) {
          context.commit("setEspecialidadesData", localEspecialidades);
        }
        if (localAfiliado !== null) {
          context.commit("setAfiliadoData", localAfiliado);
        }
        return true;
      } else {
        return false;
      }
    },
    logout: (context) => {
      context.commit("logout");
    },
    setTokenAfiliado: (context, payload) => {
      context.commit("setTokenAfiliado", payload);
    },
    setTokenAgenda: (context, payload) => {
      context.commit("setTokenAgenda", payload);
    },
    setTokenAuth: (context, payload) => {
      context.commit("setTokenAuth", payload);
    },
    setGrupoFamiliarData: (context, payload) => {
      context.commit("setGrupoFamiliarData", payload);
    },
    setAfiliadoData: (context, payload) => {
      context.commit("setAfiliadoData", payload);
    },
    setAfiliadoCita: (context, payload) => {
      context.commit("setAfiliadoCita", payload);
    },
    setEspecialidadCita: (context, payload) => {
      context.commit("setEspecialidadCita", payload);
    },
    setFechaCita: (context, payload) => {
      context.commit("setFechaCita", payload);
    },
    setIdCita: (context, payload) => {
      context.commit("setIdCita", payload);
    },
    setCupCita: (context, payload) => {
      context.commit("setCupCita", payload);
    },
    setCupsAfiliado: (context, payload) => {
      context.commit("setCupsAfiliado", payload);
    },
    setEdadAfil: (context, payload) => {
      context.commit("setEdadAfil", payload);
    },
    setAuthStep: (context,payload) => {
      context.commit("setAuthStep", payload);
    },
    loginApi: (context, payload) => {
      return new Promise(async (resolve, reject) => {
        AuthService.loginApi().then(rta => {          
          context.commit("setTokenAuth", rta.tokenAuth);
          context.commit("setTokenAgenda", rta.tokenAgenda);
          context.commit("setTokenAfiliado", rta.tokenAfil);          
          resolve();
        }).catch(err => {
          reject(err.message);
        })
        // AuthService.login().then(async (rta) => {
        //   if (rta.Valido === true) {
        //     context.commit("setTokenAuth", rta.Token);
        //     context.commit("setAfiliadoData", payload);
        //     await AfiliadoService.getToken().then(async (rta) => {
        //       // console.log("get grupo");
        //       // await context.dispatch("getGrupoFamiliarAfil");
        //     });
        //     await AgendaServiceST.getToken();

        //     resolve();
        //   } else {
        //     // console.log("fallo", rta);
        //     reject(rta.Error);
        //   }
        // });
      });
    },
    validateAuthToken: (context) => {
      return new Promise((resolve, reject) => {
        AuthService.validateToken(context.state.tokenAuth)
          .then((rta) => {
            if (rta.Valido === true) {
              resolve(true);
            }
            reject(rta.Error);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getGrupoFamiliarAfil: async (context,payload) => {   
      let identificacionAfil = context.state.identificationTypes.find(e => e.value == payload.identificacionId);
      console.log('payload',payload)
      return new Promise((resolve, reject) => {
        //Si no es afiliado salud total, asignamos los datos del afiliado
        if(!context.state.esAfiliadoSaludTotal){
          context.dispatch("setAfiliadoData",{
            identificacion: payload.identificacion,
            identificacionId: identificacionAfil.id
          });
          let grupoFami = [{
            "BeneficiarioId":payload.identificacion,
            "TipoDocumento":identificacionAfil.valueAdx,
            "BeneficiarioTipoId":payload.identificacionId,
            "BeneficiarioConsecutivo":0,
            "Nombres":context.state.nombreAfiliadoAdx,
            // "Direccion":"CL 13 16 111 T 1 AP 707",
            // "Telefono":"0",
            // "CiudadId":"68001",
            // "ipsmedicaid":"ALI_CAMPES",
            // "ipsodontologicaid":"ALIANZACAB",
            // "CotizanteId":"1098605678",
            // "CotizanteTipoId":"C",
            // "Parentesco":"COTIZANTE",
            // "EstadoDetallado":"Activo",
            // "TipoBeneficiarioId":0,
            // "UPC":"N ",
            // "EsCotizanteSiNo":0,
            // "TieneContratoVigente":1,
            // "Contrato":"91672552",
            // "EstadoServicio":1,
            // "Edad":36,
            // "IdBeneficiario":91672552,
            // "DocumentosFaltantes":null,
            // "EstadoGeneral":"Activo",
            // "Regimen":"C",
            // "FechaAfiliacion":"2019-06-05T00:00:00",
            // "producto":"1"
          }];
          context.commit("setGrupoFamiliarData",grupoFami)
          console.log('aqui')
          resolve(grupoFami);
        }else{
          AfiliadoService.getGrupoFamiliar({
            identificacion: payload.identificacion,
            identificacionId: identificacionAfil.id
          })
            .then((rta) => {
              context.dispatch("setAfiliadoData",{
                identificacion: payload.identificacion,
                identificacionId: identificacionAfil.id,
                nombre: rta.NombreAfil
              });
              resolve(rta);
            })
            .catch((error) => {
              reject(error);
            });
        }
        
      });
    },
    getEspecialidadesAfil: (context, payload) => {
      return new Promise(async (resolve, reject) => {
        // context.commit("setEspecialidadCita", {
        //   codigo: 385,
        //   nombre: "MEDICINA GENERAL",
        //   origen: 'ST'
        // });
        let rta = null;
        if(context.state.esAfiliadoSaludTotal){
          rta = await AgendaServiceST.getEspecialidades({
            identificacion: context.state.cita.afiliado.identificacion,
            identificacionId: context.state.cita.afiliado.identificacionId,
          })
        }
        
        let newRta = null;
        if (rta) {                   
          newRta = rta.map(e => {
            return { ...e, origen: 'ST' }
          })
        }

        //Agregamos las especialidades que necesitemos listar
        let identificacionInterfaz = context.state.identificationTypes.find(e => e.id == context.state.cita.afiliado.identificacionId)
        let adx = await AgendaServiceDx.getInfoAfiliadoInterfaz({
          identificacion: context.state.cita.afiliado.identificacion,
          identificacionId: identificacionInterfaz.idInterfaz
        })
        //Mamografia no se agrega aquí, ya que se agrega en caso de tener cups pre-aprobados en la interfaz
        if(adx.esAlianzaDental){
          adx.especialidades.push({codespecialidad: "ORT",especialidad: "CONTROL DE ORTODONCIA"}) 
          console.log(adx)
        }
        // adx.especialidades.push({codespecialidad: "MAMO",especialidad: "MAMOGRAFIAS"})
        
        let newAdx = adx.especialidades.map(e => {
          return { ...e, origen: 'DX' }
        })
        let newData = null;
        if (newRta) {
          newData = [...newRta, ...newAdx];
        } else {
          newData = [...newAdx];
        }

        // console.log('esp st',rta)
        // console.log('esp dx',adx)

        context.commit("setEdadAfil", moment().diff(moment(adx.fecNacimiento, 'YYYY-MM-DD'), 'years', false))
        context.commit("setEspecialidadesData", newData);
        // context.commit("setCupCita", adx.servicios.length > 0 ? adx.servicios[0] : '')
        // console.log('adx servicios',adx)
        context.commit("setCupsAfiliado", adx.servicios)
        resolve(newData);
      });
    },
    getAgenda: async (context, payload) => {
      return new Promise(async (resolve, reject) => {
        switch (context.state.cita.origen) {
          case 'ST':
            AgendaServiceST.getAgendaDisponible({
              identificacion: context.state.cita.afiliado.identificacion,
              identificacionId: context.state.cita.afiliado.identificacionId,
              especialidadCita: context.state.cita.especialidad,
              fechaCita: context.state.cita.fecha,
              tokenAgenda: context.state.tokenAgenda,
              reqPreparacion: false,
              codPrograma: null
            })
              .then((rta) => {
                let agenda = rta
                  .map((e) => {
                    return {
                      idCita: e.IdCita,
                      medico: e.Medico,
                      fecha: moment(e.Fecha)
                        .locale("es")
                        .format("LL"), //.format("DD-MM-YYYY"),
                      hora: moment(e.Fecha).format("hh:mm a"),
                      sede: e.IPS,
                      codSede: e.codsede,
                      dirSede: e.DirIPS,
                      duracion: e.Duracion,
                      fechaSinFormato: moment(e.Fecha).format("MM-DD-YYYY"),
                      fechaCompleta: e.Fecha
                    };
                  });
                resolve(agenda);
              })
              .catch((error) => {
                reject(error);
              });
            break;
          case 'DX':
            AgendaServiceDx.getAgendaDisponible({
              identificacion: context.state.cita.afiliado.identificacion,
              identificacionId: context.state.cita.afiliado.identificacionId,
              especialidadCita: context.state.cita.especialidad,
              fechaCita: context.state.cita.fecha,
              tokenAgenda: context.state.tokenAgenda,
              reqPreparacion: context.state.cita.cups.reqPreparacion ? context.state.cita.cups.reqPreparacion : false,
              codPrograma: context.state.cita.cups.codPrograma
            })
              .then((rta) => {
                //Aqui hacemos la logica para los espacios dobles
                let agenda = rta
                  .map((e) => {
                    return {
                      // idCita: e.IdCita,
                      medico: e.NomMedico == '' ? e.NomPrograma : e.NomMedico,
                      fecha: moment(e.Fecha.substr(0, 10))
                        .locale("es")
                        .format("LL"), //.format("DD-MM-YYYY"),
                      hora: moment(moment(e.Hora, 'HH:mm A')).format('hh:mm A'), //Cambiamos a formato de 12 horas, para mostrar
                      sede: e.NomSede,
                      codSede: e.CodSede,
                      dirSede: e.DirSede,
                      // duracion: e.Duracion,
                      fechaSinFormato: moment(e.Fecha).format("MM-DD-YYYY"),
                      fechaCompleta: e.Fecha,
                      codigoCups: context.state.cita.cups,
                      tipoDocAfil: context.state.cita.afiliado.tipoDoc,
                      codMedico: e.CodMedico,
                      duracion: e.TiempoAt
                    };
                  });
                resolve(agenda);
              })
              .catch((error) => {
                reject(error);
              });
          default:
            break;
        }

      });
    },
    getInfoAfiliadoInterfaz: (context, payload) => {
      return new Promise((resolve, reject) => {
        let info = AgendaServiceDx.getInfoAfiliadoInterfaz(payload)
        resolve(info);
      });
    },
    getInfoAfiliado: (context) => {
      return new Promise((resolve, reject) => {
        let afil = context.state.grupoFamiliar.find(
          (e) => e.BeneficiarioId == context.state.cita.afiliado.identificacion
        );
        resolve(afil);
      });
    },
    asignarCita: (context, payload) => {
      return new Promise((resolve, reject) => {
        let datosCita = payload;
        let infoAfil = context.state.grupoFamiliar.find(
          (e) => e.BeneficiarioId == context.state.cita.afiliado.identificacion
        );
        let identificacionAfil = context.state.identificationTypes.find(
          (e) => e.value == infoAfil.BeneficiarioTipoId
        );
        switch (context.state.cita.origen) {
          case 'ST':
            AgendaServiceST.asignarCita({
              numero_documento: context.state.cita.afiliado.identificacion,
              tipo_documento: context.state.cita.afiliado.identificacionId,
              idCita: datosCita.idCita,
              planSalud: 1,
              sede: datosCita.sede,
              duracion: datosCita.duracion,
              fechaCita: datosCita.fechaCompleta,
              codespecialidad: context.state.cita.especialidad,
            })
              .then((rta) => {
                resolve(rta);
              })
              .catch((error) => {
                reject(error);
              });
            break;
          case 'DX':
            console.log(datosCita.espaciosCitas)
            AgendaServiceDx.asignarCita({
              numero_documento: context.state.cita.afiliado.identificacion,
              tipo_documento: context.state.cita.afiliado.tipoDoc,
              idCita: datosCita.idCita,
              planSalud: 1,
              sede: datosCita.sede,
              duracion: datosCita.duracion,
              fechaCita: datosCita.fechaCompleta.substring(0, 10),
              fechaReg: moment(datosCita.fechaCompleta.substring(0, 10)).format('YYYYMMDD'),
              fechaCorte: moment(datosCita.fechaCompleta.substring(0, 10)).format('YYYYMM'),
              horaCita: moment(moment(datosCita.hora, 'hh:mm A')).format('HH:mm A'),  //Cambiamos a formato de 24 horas, asi acepta el api
              codespecialidad: context.state.cita.especialidad,
              codigoCups: context.state.cita.cups.codigo,
              codMedico: datosCita.codMedico,
              espaciosCitas: datosCita.espaciosCitas.map(e => e.hora)
              // doble: datosCita.segundaCita ? true : false,
              // segundaCita: moment(moment(datosCita.segundaCita, 'hh:mm A')).format('HH:mm A'),
            })
              .then((rta) => {
                resolve(rta);
              })
              .catch((error) => {
                reject(error);
              });
          default:
            break;
        }
      });
    },
    getCitasVigentes: (context, payload) => {
      return new Promise(async (resolve, reject) => {
        let infoAfil = context.state.grupoFamiliar.find(
          (e) => e.BeneficiarioId == payload
        );
        let identificacionAfil = context.state.identificationTypes.find(
          (e) => e.value == infoAfil.BeneficiarioTipoId
        );
        let citasSt = await AgendaServiceST.citasVigentes({
          numero_documento: infoAfil.BeneficiarioId,
          tipo_documento: identificacionAfil.id,
          aplicativoOrigen: 2,
        })
        // let citasSt = null
        let citasAdx = await AgendaServiceDx.citasVigentes({
          numero_documento: infoAfil.BeneficiarioId
        })
        let listCitas = []
        if (citasSt) {
          listCitas = [...citasSt, ...citasAdx.map(e => {
            return {
              ...e,
              hora: moment(moment(e.hora, 'HH:mm A')).format('hh:mm A'), //Cambiamos a formato de 12 horas, para mostrar
            }
          })];
        } else {
          listCitas = [...citasAdx.map(e => {
            return {
              ...e,
              hora: moment(moment(e.hora, 'HH:mm A')).format('hh:mm A'), //Cambiamos a formato de 12 horas, para mostrar
            }
          })];
        }
        resolve(listCitas)
      });
    },
    cancelaCita: (context, payload) => {
      console.log('payload',payload)
      return new Promise((resolve, reject) => {
        let infoAfil = context.state.grupoFamiliar.find(
          (e) => e.BeneficiarioId == payload.afiliado
        );
        let identificacionAfil = context.state.identificationTypes.find(
          (e) => e.value == infoAfil.BeneficiarioTipoId
        );
        switch (payload.origen) {
          case 'ST':
            AgendaServiceST.cancelaCita({
              numero_documento: infoAfil.BeneficiarioId,
              tipo_documento: identificacionAfil.id,
              aplicativoOrigen: "AgendaIT",
              idCita: payload.idCita,
              especialidad: payload.especialidad,
              sede: payload.unidad
            })
              .then((rta) => {
                resolve(rta);
              })
              .catch((error) => {
                reject(error);
              });
            break;
          case 'DX':
            AgendaServiceDx.cancelaCita({
              numero_documento: infoAfil.BeneficiarioId,
              tipo_documento: identificacionAfil.valueAdx,
              medico: payload.medicoAdx,
              fecha: moment(payload.fechaCompleta).format('YYYYMMDD'),
              hora: moment(moment(payload.hora, 'hh:mm A')).format('HH:mm A'),  //Cambiamos a formato de 24 horas, asi acepta el api
              especialidad: payload.especialidad,
              codEspecialidad: payload.codEspecialidad,
              sede: payload.unidad
            })
              .then((rta) => {
                if (rta.Error) {
                  reject(rta.Error)
                }
                resolve(rta);
              })
              .catch((error) => {
                reject(error);
              });
          default:
            break;
        }

      });
    },
    asignarCitaDoble: (context, payload) => {
      return new Promise((resolve, reject) => {
        let datosCita = payload;
        let infoAfil = context.state.grupoFamiliar.find(
          (e) => e.BeneficiarioId == context.state.cita.afiliado.identificacion
        );
        let identificacionAfil = context.state.identificationTypes.find(
          (e) => e.value == infoAfil.BeneficiarioTipoId
        );
        AgendaServiceDx.asignarCita({
          numero_documento: context.state.cita.afiliado.identificacion,
          tipo_documento: context.state.cita.afiliado.tipoDoc,
          idCita: datosCita.idCita,
          planSalud: 1,
          sede: datosCita.sede,
          duracion: datosCita.duracion,
          fechaCita: datosCita.fechaCompleta.substring(0, 10),
          fechaReg: moment(datosCita.fechaCompleta.substring(0, 10)).format('YYYYMMDD'),
          fechaCorte: moment(datosCita.fechaCompleta.substring(0, 10)).format('YYYYMM'),
          horaCita: moment(moment(datosCita.hora, 'hh:mm A')).format('HH:mm A'),  //Cambiamos a formato de 24 horas, asi acepta el api
          codespecialidad: context.state.cita.especialidad,
          codigoCups: context.state.cita.cups,
          codMedico: datosCita.codMedico,

        })
          .then((rta) => {
            resolve(rta);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    sendEmailVerifycation(context,payload){
      return new Promise((resolve,reject) => {
        axios.post(`${context.state.urlProxyApi}/send-verifycation-email`,{
          email: payload.email,
          identificacion: payload.identificacion,
          tipoIdentificacion: payload.identificacionId,
          newEmail: payload.isNewEmail
        }).then(rta => {
          resolve(rta.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    validateCodeEmail(context,payload){
      return new Promise((resolve,reject) => {
        axios.post(`${context.state.urlProxyApi}/validate-code-email`,{
          authCode: payload.authCode,
          identificacion: payload.identificacion,
          tipoIdentificacion: payload.identificacionId,
          newEmail: payload.newEmail
        }).then(rta => {
          resolve(rta.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    firstStepLogin(context,payload){
      return new Promise((resolve,reject) => {
        axios.post(`${context.state.urlProxyApi}/first-step-login`,{
          identificacion: payload.identificacion,
          tipoIdentificacion: payload.identificacionId,
        },{
          headers: {
            authorization: context.state.tokenAfiliado
          }
        }).then(rta => {
          resolve(rta.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    getSedesAfiliado(context,payload){      
      return new Promise((resolve,reject) => {
        AgendaServiceST.getSedes({
          codCiudad: payload.codCiudad,
          especialidad: payload.especialidad,
        }).then(rta => {
          resolve(rta)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  modules: {
    'ApiSaludTotal': ApiSaludTotal
  },
});
