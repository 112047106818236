import axios from "axios";
import store from "../store/index";
import moment from 'moment'

const getAgendaOrtodoncia = (data) => {
    let urlApiOdontologia = store.state.urlProxyApi + '/odontologia/ortodoncia';  
    return new Promise((resolve, reject) => {
        let formatFechaCita = moment(data.fechaCita).format('YYYYMMDD')
        axios    
        .get(`${urlApiOdontologia}/agenda/citas-disponibles/${formatFechaCita}`)
        .then((response) => {                 
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

const asignarCitaOrtodoncia = (data) => {
    let urlApiMamografias = store.state.urlProxyApi + '/odontologia/ortodoncia';
    return new Promise((resolve, reject) => {
      axios
        .post(`${urlApiMamografias}/agenda/asignar-cita`, {
            ...data, 
            dataToReport: {
            tipoDoc: data.tipo_documento,
            documento: data.numero_documento,
            especialidad: data.especialidad,
            sede: data.sede
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

export {
    getAgendaOrtodoncia,
    asignarCitaOrtodoncia
};
