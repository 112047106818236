import axios from "axios";
import store from "../store/index";
import moment from 'moment'


const getAgendaMamografia = (data) => {
  let urlApiMamografias = store.state.urlProxyApi + '/imagenologia/mamografia';
  return new Promise((resolve, reject) => {
    let formatFechaCita = moment(data.fechaCita).format('YYYYMMDD')
    axios
      .get(`${urlApiMamografias}/agenda/citas-disponibles/${formatFechaCita}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const asignarCitaMamografia = (data) => {
  let urlApiMamografias = store.state.urlProxyApi + '/imagenologia/mamografia';
  return new Promise((resolve, reject) => {
    axios
      .post(`${urlApiMamografias}/agenda/asignar-cita`, {
          ...data, 
          dataToReport: {
          tipoDoc: data.tipo_documento,
          documento: data.numero_documento,
          especialidad: data.especialidad,
          sede: data.sede
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export {
  getAgendaMamografia,
  asignarCitaMamografia
};
